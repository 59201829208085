class Constants {

}

Constants.AES_SALT = "tenKsZVM4EcSSPQKxKt7BnZRPkyaUcb5";

Constants.BASE_URL = "https://login.learnmatch.com/";

Constants.BRANCH_KEY = 'key_live_lpAFbGtFGwWlbAmPWkJD3ofjCDjUUPUH';

export default Constants;
