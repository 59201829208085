import * as firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyAwIuwYxErK7qPzThkUAPUbDqbrsnis5Fw",
    authDomain: "learnmatch-b2d69.firebaseapp.com",
    databaseURL: "https://learnmatch-b2d69.firebaseio.com",
    storageBucket: "learnmatch-b2d69.appspot.com"
};

try{
    console.log(firebase.app());
} catch(e){
    firebase.initializeApp(config);
}

export default firebase;
