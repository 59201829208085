import firebase from './firebase';
import Constants from './Constants'

const CryptoJS = require('crypto-js');

class FirebaseConnector {

    communities = async () => {
        return (await firebase.database().ref('Communities').orderByKey().startAt("100000").once('value')).val();
    };

    locales = async () => {
        let locales = (await firebase.database().ref('Locales').once('value')).val();
        delete locales.version;

        for(let key in locales){
            if(locales.hasOwnProperty(key)){
                locales[key].imageUrl = this.getDownloadUrl('Images/Locales/' + locales[key].image);
            }
        }

        for(let key in locales){
            if(locales.hasOwnProperty(key)){
                locales[key].imageUrl = await locales[key].imageUrl;
            }
        }

        return locales;
    };

    isCompanyCodeValidAndUnused = async (companyId, code) => {
        let returnValue = { exists: false, valid: false, expired: false };
        let companyCode = (await firebase.database().ref('companies/' + companyId + '/' + code).once('value')).val();
        if (companyCode != null) {
            returnValue.exists = true;
            if(companyCode.used === false || companyCode.count > 0){
                returnValue.valid = true;
            }
            if(companyCode.expirationDate < new Date().getTime()){
                returnValue.expired = true;
            }
        }
        return returnValue;
    };

    writeRegistration = async (firebaseId, companyId, code, validated, langCode) => {
        if(firebaseId && companyId && code && langCode){
            await firebase.database().ref('registrations/' + firebaseId).set({
                companyId: companyId,
                voucherCode: code,
                validated: validated ? new Date().getTime() : 0,
                languageCode: langCode
            });
        } else {
            throw "input missing";
        }
    };

    addBranchLink = async (firebaseId, branchLink) => {
        if(firebaseId && branchLink){
            await firebase.database().ref('registrations/' + firebaseId + "/branchLink").set(branchLink);
        } else {
            throw "input missing";
        }
    };

    markCompanyCodeAsUsed = async (companyId, code, uID) => {
        let returnValue = {success: false, expirationDate: ""};
        try {
            await firebase.database().ref('companies/' + companyId + '/' + code).transaction((code) => {
                if (code !== null) {
                    // check for single use codes
                    if (code.used !== null && code.used === false && !code.uID && (!code.expirationDate || code.expirationDate > new Date().getTime())) {
                        code.used = true;
                        code.uID = uID;
                        code.timestamp = firebase.database.ServerValue.TIMESTAMP;
                        returnValue.success = true;
                    }
                    // check for multi use codes
                    else if (code.count && code.count > 0 && (!code.expirationDate || code.expirationDate > new Date().getTime())) {
                        code.count = code.count - 1;
                        if (!code.uIDs) {
                            code.uIDs = [];
                        }
                        code.uIDs.push({
                            uID: uID,
                            timestamp: firebase.database.ServerValue.TIMESTAMP
                        });
                        returnValue.success = true;
                    }
                    // check for exirationDate if marking was successful
                    if (returnValue.success && code.expirationDate) {
                        returnValue.expirationDate = code.expirationDate;
                    }
                }
                return code;
            });
        } catch (e) {
            return returnValue;
        }
        return returnValue;
    };

    getDownloadUrl = (path) => {
        return firebase.storage().ref(path).getDownloadURL();
    };

    registerWithMail = async (mail, password) => {
        let auth = await firebase.auth().createUserWithEmailAndPassword(mail, password);
        return auth.user.toJSON();
    };

    signInWithMail = async (mail, password) => {
        let auth = await firebase.auth().signInWithEmailAndPassword(mail, password);
        return auth.user.toJSON();
    };

    sendVerificationMail = async (uid, mail, password, companyId, voucher, langCode) => {
        let encryptedPassword = this.encryptPassword(password);

        let actionCodeSettings = {
            url: Constants.BASE_URL + '?uid=' + encodeURIComponent(uid) + '&mail=' + encodeURIComponent(mail) + '&p=' + encodeURIComponent(encryptedPassword) + '&company=' + encodeURIComponent(companyId) + '&langCode=' + encodeURIComponent(langCode) + '&voucher=' + encodeURIComponent(voucher)
        };

        await firebase.auth().currentUser.sendEmailVerification(actionCodeSettings);
    };

    deleteUser = async () => {
        await firebase.auth().currentUser.delete();
    };

    encryptPassword = (password) => {
        return CryptoJS.AES.encrypt(password, Constants.AES_SALT).toString();
    };

    decryptPassword = (encryptedPassword) => {
        return CryptoJS.AES.decrypt(encryptedPassword, Constants.AES_SALT).toString(CryptoJS.enc.Utf8);
    };
}

export default FirebaseConnector;
