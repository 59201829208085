import React from 'react';
import './App.css';
import PromiseComponent from './PromiseComponent'
import FirebaseConnector from './FirebaseConnector';
import i18next from 'i18next';
import {Sentry} from 'react-activity';
import 'react-activity/dist/react-activity.css';
import branch from 'branch-sdk';
import Constants from "./Constants";

const STAGES = {
    LOADING: 1,
    START: 2,
    PROFILE: 3,
    VERIFY: 4,
    BRANCH_LINK: 5,
    ERROR: 6,
    LOGIN: 7,
};

class App extends PromiseComponent {

    state = {
        communityId: 0,
        stage: STAGES.LOADING,
        voucher: '',
        voucherValid: false,
        voucherLoading: false,
        voucherResponse: '',
        mail: '',
        password: '',
        acceptedTerms: false,
        company: null,
        branchLink: '',
        imgSource: '',
        error: '',
        locales: {},
        languageSelectionOpen: false,
    };

    firebaseConnector = new FirebaseConnector();
    communities = {};

    componentWillMount = async () => {
        let localesTask = this.firebaseConnector.locales();
        this.communities = await this.firebaseConnector.communities();
        let locales = await localesTask;

        await this.promisedSetState({locales: locales});

        let isDeepLink = await this.checkDeeplink();

        if (!isDeepLink) {
            let urlParams = new URLSearchParams(window.location.search);
            if (urlParams.get('uid') && urlParams.get('mail') && urlParams.get('p') && urlParams.get('company') && urlParams.get('voucher') && urlParams.get('langCode')) {
                let mail = decodeURIComponent(urlParams.get('mail'));
                let pEnc = decodeURIComponent(urlParams.get('p'));
                let company = decodeURIComponent(urlParams.get('company'));
                let voucher = decodeURIComponent(urlParams.get('voucher'));
                let uid = decodeURIComponent(urlParams.get('uid'));
                let langCode = decodeURIComponent(urlParams.get('langCode'));
                await i18next.changeLanguage(langCode);
                await this.promisedSetState({voucher: voucher});
                await this.checkVoucher(company - 100000);
                if (this.state.voucherValid) {
                    await this.promisedSetState({stage: STAGES.LOADING});
                    await this.firebaseConnector.signInWithMail(mail, this.firebaseConnector.decryptPassword(pEnc));
                    await this.firebaseConnector.writeRegistration(uid, company, voucher, true, i18next.language.substr(0, 2));
                    await this.firebaseConnector.markCompanyCodeAsUsed(company - 100000, voucher, uid);
                    await this.goOnToBranchLink(uid, mail, pEnc, company, voucher, new Date().getTime());
                    await this.firebaseConnector.addBranchLink(uid, this.state.branchLink);
                }
            } else {
                await this.promisedSetState({stage: STAGES.START});
            }
        }
    };

    checkDeeplink = async (attempt = 0) => {
        await new Promise((resolve) => {
            branch.init(Constants.BRANCH_KEY, async (error, data) => {
                if(error && attempt < 5) {
                    await this.checkDeeplink(attempt + 1);
                } else if (!error && data.data_parsed && data.data_parsed.CompanyID && data.data_parsed.VoucherCode) {
                    await this.promisedSetState({voucher: data.data_parsed.VoucherCode});
                    await this.checkVoucher(data.data_parsed.CompanyID);
                    resolve(true);
                }
                resolve(false);
            });
        });
    };

    registerWithMail = async () => {
        if (this.verifyMail(this.state.mail)) {
            if (this.state.password.length > 5) {
                await this.promisedSetState({
                    stage: STAGES.LOADING
                });
                try {
                    let user = await this.firebaseConnector.registerWithMail(this.state.mail, this.state.password);
                    if (this.state.company.VerifyEmail) {
                        await this.sendVerificationMail(user.uid);
                    } else {
                        await this.firebaseConnector.writeRegistration(user.uid, this.state.communityId, this.state.voucher, false, i18next.language.substr(0, 2));
                        await this.firebaseConnector.markCompanyCodeAsUsed(this.state.communityId - 100000, this.state.voucher, user.uid);
                        await this.goOnToBranchLink(user.uid, this.state.mail, this.firebaseConnector.encryptPassword(this.state.password), this.state.communityId, this.state.voucher, 0);
                        await this.firebaseConnector.addBranchLink(user.uid, this.state.branchLink);
                    }
                } catch (e) {
                    let alertMessage = i18next.t("ALERT__AN_ERROR_OCCURED_TITLE");
                    if (e.code === "auth/email-already-in-use") {
                        await this.promisedSetState({stage: STAGES.LOGIN});
                    } else {
                        if (e.code === "auth/weak-password") {
                            alertMessage = i18next.t("ALERT__ERROR_WEAK_PASSWORD_MESSAGE");
                        } else if (e.code === "auth/invalid-email") {
                            alertMessage = i18next.t("ALERT__ERROR_INVALID_EMAIL_MESSAGE");
                        } else if (typeof e.Code !== 'undefined' && typeof e.Message !== 'undefined') {
                            alertMessage = i18next.t("ALERT__CODE_X_MESSAGE_Y_MESSAGE", {
                                code: e.Code,
                                message: e.Message
                            });
                        }
                        alert(
                            alertMessage
                        );
                    }
                    this.setState({stage: STAGES.PROFILE});
                }
            } else {
                alert(
                    i18next.t("ALERT__ERROR_WEAK_PASSWORD_MESSAGE"),
                )
            }
        } else {
            alert(
                i18next.t("ALERT__ERROR_INVALID_EMAIL_MESSAGE")
            )
        }
    };

    sendVerificationMail = async (uid) => {
        await this.firebaseConnector.sendVerificationMail(uid, this.state.mail, this.state.password, this.state.communityId, this.state.voucher, i18next.language.substr(0, 2));
        await this.promisedSetState({stage: STAGES.VERIFY});
    };

    goOnToBranchLink = async (uid, mail, encryptedPassword, companyId, voucher, validated, attempt = 0) => {
        return new Promise((resolve) => {
            branch.link({
                channel: 'Phase6-Berufe WebClient',
                feature: 'SignUp',
                campaign: 'SignUp',
                data: {
                    language: i18next.language.substr(0, 2),
                    mail: mail,
                    p: encryptedPassword,
                    companyId: companyId,
                    voucher: voucher,
                    uid: uid,
                    validated: validated
                },
            }, async (error, link) => {
                if (error) {
                    if(attempt < 5) {
                        await this.goOnToBranchLink(uid, mail, encryptedPassword, companyId, voucher, validated, attempt + 1);
                    } else {
                        let fallbackLink = "https://p6.app.link";
                        await this.promisedSetState({branchLink: fallbackLink, stage: STAGES.BRANCH_LINK});
                        resolve();
                    }
                } else {
                    await this.promisedSetState({branchLink: link, stage: STAGES.BRANCH_LINK});
                    resolve();
                }
            });
        })
    };

    verifyMail = (mail) => {
        let pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
        return mail && mail.match(pattern) !== null;
    };

    changeVoucherText = async (event) => {
        let text = event.target.value;
        text = text.replace(/\s/g, "").toUpperCase();
        await this.promisedSetState({
            voucher: text,
            voucherValid: false,
        });
        if (this.state.voucher.length === 6) {
            await this.promisedSetState({
                voucherLoading: true,
                voucherResponse: "",
            });
            await this.checkVoucher()
        } else if (this.state.voucher.length > 6) {
            await this.promisedSetState({
                voucherLoading: false,
                voucherResponse: "",
            });
        }
    };

    changeMailText = (event) => {
        let text = event.target.value.replace(/\s/g, "");
        this.setState({mail: text});
    };

    changePasswordText = (event) => {
        let text = event.target.value.replace(/\s/g, "");
        this.setState({password: text});
    };

    checkVoucher = async (companyId = null) => {
        if (this.state.voucher.length === 6) {
            try {
                let codeInformation = { exists: false, valid: false, expired: false };
                if (companyId) {
                    // community via deep link
                    companyId = +companyId;
                    let communityId = 100000 + companyId;
                    if (this.communities[communityId] && this.communities[communityId].ExpirationDate && this.communities[communityId].ExpirationDate > Date.now() && this.communities[communityId].Active) {
                        codeInformation = await this.firebaseConnector.isCompanyCodeValidAndUnused(companyId, this.state.voucher);
                        await this.promisedSetState({communityId: communityId});
                    }
                } else {
                    // no community selected yet
                    for (let id in this.communities) {
                        if (this.communities.hasOwnProperty(id) && this.communities[id].ExpirationDate && this.communities[id].ExpirationDate > Date.now() && this.communities[id].Active) {
                            // sponsor is not expired
                            codeInformation = await this.firebaseConnector.isCompanyCodeValidAndUnused(+id - 100000, this.state.voucher);
                            // check if valid sponsor is found
                            if (codeInformation.exists) {
                                await this.promisedSetState({communityId: id});
                                break;
                            }
                        }
                    }
                }
                if (codeInformation.valid && !codeInformation.expired) {
                    await this.loadSponsorImg();
                    await this.promisedSetState({
                        voucherLoading: false,
                        voucherValid: true,
                        voucherResponse: i18next.t('MESSAGE__VALID_VOUCHER_CODE_FOR_X_COMMUNITY_ENTERED', {name: this.communities[this.state.communityId].Name.default}),
                    });
                    await this.goOnWithValidKey();
                } else {
                    let errorString = "MESSAGE__INVALID_VOUCHER_CODE_ENTERED";
                    if(codeInformation.exists) {
                        errorString = "MESSAGE__USED_VOUCHER_CODE_ENTERED";
                    }
                    if(codeInformation.expired) {
                        errorString = "MESSAGE__EXPIRED_VOUCHER_CODE_ENTERED";
                    }
                    this.promisedSetState({
                        stage: STAGES.START,
                        voucherLoading: false,
                        voucherResponse: i18next.t(errorString),
                    });
                }
            } catch (e) {
                alert(
                    i18next.t('ALERT__ERROR_TITLE')
                );
            }
        } else {
            this.promisedSetState({
                voucherLoading: false,
                voucherResponse: i18next.t('MESSAGE__INVALID_VOUCHER_CODE_ENTERED'),
            });
        }
    };

    goOnWithValidKey = async () => {
        let company = this.communities[0];
        if (this.state.communityId) {
            company = this.communities[+this.state.communityId];
        }
        await this.promisedSetState({company: company, stage: STAGES.PROFILE});
    };

    loadSponsorImg = async () => {
        let imgSource = '';
        if (this.state.communityId !== null) {
            let path = this.communities[this.state.communityId].ImgPath;
            imgSource = await this.firebaseConnector.getDownloadUrl(path);
        }
        await this.promisedSetState({imgSource: imgSource})
    };

    renderVoucherButtonText = () => {
        if (this.state.voucherLoading) {
            return (
                <Sentry size={12}/>
            );
        }
        return (
            <p>{i18next.t('GLOBAL_BUTTON__CONTINUE')}</p>
        );
    };

    renderVoucherInput = () => {
        return (
            <input
                maxLength="6"
                onChange={this.changeVoucherText}
                value={this.state.voucher}
                autoCorrect="false"
                autoCapitalize="characters"
                placeholder={i18next.t('FORM_PLACEHOLDER_BUSINESS_REGISTRATION__VOUCHER_CODE')}
            />
        )
    };

    renderVoucherResponse = () => {
        return (
            <div className="info-text">
                <p>
                    {
                        this.state.voucherResponse ?
                            this.state.voucherResponse :
                            i18next.t("DESCRIPTION_COMMUNITY_ACCESS__PLEASE_ENTER_ACCESS_KEY")
                    }
                </p>
            </div>
        );
    };

    renderPhase6Terms = () => {
        let urls = ["https://learnmatch.net/agb", "https://learnmatch.net/datenschutz"];
        return (
            <div className="terms-container">
                {this.renderTermsAndDataLinks(i18next.t("DESCRIPTION_REGISTRATION__TERMS_AND_CONDITION"), urls)}
            </div>
        )
    };

    renderCompanyTerms = () => {
        if (this.state.company && this.state.company.PrivacyLink && this.state.company.PrivacyLink.text && this.state.company.PrivacyLink.urls) {
            let text = this.state.company.PrivacyLink.text[i18next.language.substr(0, 2)] || this.state.company.PrivacyLink.text.default;
            let urls = this.state.company.PrivacyLink.urls[i18next.language.substr(0, 2)] || this.state.company.PrivacyLink.urls.default;
            if (text && urls.length > 0) {
                return (
                    <div className="terms-container">
                        {this.renderTermsAndDataLinks(text, urls)}
                    </div>
                );
            }
        }
        //fallback
        return this.renderPhase6Terms();
    };

    renderTermsAndDataLinks = (translation, urls) => {
        if (urls.length === 0) return this.renderPhase6Terms();
        let splittedText = translation.split('@link@');
        let parts = [];
        for (let i = 0; i < splittedText.length; i++) {
            if (i === 1) {
                parts.push(
                    <a key={"link1"} href={urls[0]} rel="noopener noreferrer" target="_blank">
                        <p>{splittedText[i] + " "}</p>
                    </a>
                );
            } else if (i === 3) {
                parts.push(
                    <a key={"link2"} href={urls[1]} rel="noopener noreferrer" target="_blank">
                        <p>{splittedText[i] + " "}</p>
                    </a>
                );
            } else if (i === 5) {
                parts.push(
                    <a key={"link3"} href={urls[2]} rel="noopener noreferrer" target="_blank">
                        <p>{splittedText[i] + " "}</p>
                    </a>
                );
            } else {
                let splittedPart = splittedText[i].trim().split(' ');
                for (let j = 0; j < splittedPart.length; j++) {
                    parts.push(
                        <p key={"text" + i + j}>{splittedPart[j] + " "}</p>
                    );
                }
            }
        }

        return parts;
    };

    renderRegistration() {
        return (
            <div className="content-container">
                <div className="info-text">
                    <p>{i18next.t("DESCRIPTION_REGISTRATION__REGISTER_WITH_MAIL")}</p>
                </div>
                <input
                    type="email"
                    onChange={this.changeMailText}
                    value={this.state.mail}
                    placeholder={i18next.t("FORM_PLACEHOLDER__EMAIL_ADRESS")}
                />
                <input
                    type="password"
                    onChange={this.changePasswordText}
                    value={this.state.password}
                    placeholder={i18next.t("FORM_PLACEHOLDER__PASSWORD")}
                />
                {
                    this.renderLanguageInput()
                }
                <div className="terms-outer-container">
                    {this.renderCompanyTerms()}
                </div>
                <button onClick={this.registerWithMail}>
                    <p>
                        {i18next.t("BUTTON__ACCEPT_AND_START")}
                    </p>
                </button>
            </div>
        );
    };

    renderContent() {
        switch (this.state.stage) {
            case STAGES.LOADING:
                return (
                    <div className="content-container">
                        <Sentry/>
                        <p className="info-text">{i18next.t("LOADING__LOADING_ELLIPSIS")}</p>
                    </div>
                );
            case STAGES.START:
                return (
                    <div className="content-container">
                        {
                            this.renderVoucherResponse()
                        }
                        {
                            this.renderVoucherInput()
                        }
                        <button
                            disabled={!this.state.voucherValid}
                            onClick={this.goOnWithValidKey}>
                            {
                                this.renderVoucherButtonText()
                            }
                        </button>
                    </div>
                );
            case STAGES.PROFILE:
                return this.renderRegistration();
            case STAGES.VERIFY:
                return (
                    <p>{i18next.t("WEB__EMAIL_SENT")}</p>
                );
            case STAGES.BRANCH_LINK:
                return (
                    <div className="content-container">
                        <p>{i18next.t("WEB__SUCCESSFULLY_REGISTERED")}</p>
                        <p><a href={this.state.branchLink}>{ this.state.branchLink }</a></p>
                        <p>{i18next.t("GLOBAL__OR")}</p>
                        <a href={this.state.branchLink}><img src={require('./assets/img/download-app-store.svg')} className="download-link-image" alt="Download App"/></a>
                    </div>
                );
            case STAGES.ERROR:
                return (
                    <div>
                        <p>{i18next.t("ALERT__OTHER_ERROR_MESSAGE")}</p>
                        <button
                            onClick={() => this.setState({stage: STAGES.START})}>
                            <p>{i18next.t("GLOBAL__BACK")}</p>
                        </button>
                    </div>
                );
            case STAGES.LOGIN:
                return (
                    <div className="content-container">
                        <p>{i18next.t("WEB__EMAIL_ALREADY_IN_USE")}</p>
                        <button
                            onClick={() => this.setState({stage: STAGES.PROFILE})}>
                            <p>{i18next.t("GLOBAL__BACK")}</p>
                        </button>
                    </div>
                );
            default:
                this.setState({stage: STAGES.START});
        }
    }


    renderSponsorImage = () => {
        if (this.state.imgSource) {
            return (
                <div className="title-image-container">
                <img src={this.state.imgSource} className="title-image" alt="Company Logo"/>
                </div>
            )
        }
    };


    renderTitle = () => {
        if (this.state.company) {
            return (
                <h1>{this.state.company.Name.default}</h1>
            )
        } else {
            return (
                <h1>phase6 Berufe</h1>
            )
        }
    };

    renderLanguageInput = () => {
        return (
            <div className="language-container"
                 onClick={async () => {
                     await this.promisedSetState({languageSelectionOpen: true});
                 }}>
                <img className="language-flag"
                     alt={'flag-' + i18next.language.substr(0, 2)}
                     src={this.state.locales[i18next.language.substr(0, 2)].imageUrl}/>
                <p className="language-label">
                    {this.state.locales[i18next.language.substr(0, 2)].label}
                </p>
                <p className="language-selection-icon">
                    >
                </p>
            </div>)
    };

    renderLanguageSelection = () => {
        if (this.state.languageSelectionOpen) {
            let options = [];
            for (let key in this.state.locales) {
                if (this.state.locales.hasOwnProperty(key)) {
                    options.push(
                        <div className="language-container-list-item"
                             key={key} onClick={async () => {
                            await i18next.changeLanguage(key);
                            await this.promisedSetState({languageSelectionOpen: false});
                        }}>
                            <img className="language-flag"
                                 alt={'flag-' + key}
                                 src={this.state.locales[key].imageUrl}/>
                            <p className="language-label">
                                {this.state.locales[key].label}
                            </p>
                        </div>
                    );
                }
            }

            return (
                <div className="language-container-list">
                    {
                        options
                    }
                </div>
            );
        }
    };

    render() {
        return (
            <div className="all-container">
                {
                    this.renderLanguageSelection()
                }
                <div className="upper-third"
                     style={{background: this.state.company ? this.state.company.PrimaryColor : ""}}>
                    <div className="title-container">
                        {
                            this.renderSponsorImage()
                        }
                        {
                            this.renderTitle()
                        }
                    </div>
                </div>
                <div className="lower-two-thirds">
                    {
                        this.renderContent()
                    }
                </div>
            </div>
        )
    }
}

export default App;
